import { faLock, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

function Login() {
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useContext(AuthContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  const validate = () => {
    if (username === undefined || username === "") {
      setErrors(["Email must exist"]);
      return false;
    }

    if (password === undefined || password === "") {
      setErrors(["Password must exist"]);
      return false;
    }
    return true;
  };

  async function loginUser(
    username: string | undefined,
    password: string | undefined
  ) {
    return await fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${username}&password=${password}`,
    }).then((data) => data.json());
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setErrors([]);

    e.preventDefault();

    const validation = validate();
    if (!validation) {
      return false;
    }

    try {
      const result = await loginUser(username, password);

      if (result.error) {
        setErrors([result.error]);
        return false;
      }

      dispatch({
        loggedIn: true,
        user: {
          id: result.user.id,
          email: result.user.email,
          name: result.user.name,
          providerId: result.access_token,
          createdAt: new Date(result.user.createdAt),
        },
      });

      history.push("/");
      return true;
    } catch (error: any) {
      setErrors(["Connection error"]);
      return false;
    }
  };

  return (
    <div className="container">
      <div className="utility-form">
        <div className="text-center mb-4">
          <h3>Welcome back! 👋</h3>
          <p className="text-muted">Log into your account to continue</p>
        </div>
        <form
          onSubmit={(e) => {
            return handleSubmit(e);
          }}
        >
          {errors && errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              <ul className="mb-0">
                {errors.map((e: string) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon fixedWidth={true} icon={faUser} />
              </span>
              <input
                type="email"
                className="form-control border-start-0 ps-1"
                placeholder="Email address"
                id="email"
                autoComplete="username"
                aria-describedby="emailHelp"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <small id="emailHelp" className="text-muted ">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faLock} />
            </span>
            <input
              type="password"
              className="form-control border-start-0 ps-1"
              placeholder="Password"
              autoComplete="current-password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" id="remember" />
            <label className="form-check-label" htmlFor="remember">
              Remember Me
            </label>
          </div>

          <div className="d-grid col-6 mx-auto">
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </form>
        <div className="text-center mt-2">
          <small className="text-muted">
            Don't have an account yet? <Link to="/signup">Create One</Link>
          </small>
        </div>
      </div>
    </div>
  );
}

export default Login;
