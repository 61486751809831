import React from "react";
import loader from "../assets/loader.png";

function Loader() {
  return (
    <div className="d-flex container justify-content-center">
      <img src={loader} alt="Loading" />
    </div>
  );
}

export default Loader;
