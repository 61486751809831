import { formatDistance } from "date-fns";
import { useMutation } from "jsonapi-react";
import React, { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { AuthContext, User } from "../components/AuthContext";
import Input from "../components/Input";
import { capitalize } from "../utils/helpers";

function Account() {
  const history = useHistory();
  const [state, dispatch] = useContext(AuthContext);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [alert, setAlert] = React.useState<string>("");
  const [updateUser, { error }] = useMutation<User>(["users", state.user?.id]);
  const {
    register,
    formState: { errors: userErrors },
    handleSubmit,
    reset,
    formState,
  } = useForm<User>();

  const onSubmit: SubmitHandler<User> = async (results, e) => {
    let logout = false;
    setAlert("");

    try {
      if (results.password !== results.passwordConfirmation) {
        setErrors(["Passwords do not match."]);
        return;
      }

      let formResults: any = {
        name: results.name,
        email: results.email,
      };

      if (
        results.password &&
        results.passwordConfirmation &&
        results.password === results.passwordConfirmation
      ) {
        formResults.password = results.password;
        logout = true;
      }

      const user = await updateUser(formResults);

      if (error) {
        setErrors(
          error.errors.map((e: { message: string }) => capitalize(e.message))
        );
        return;
      }

      if (!user.data) {
        return;
      }

      if (user.data.email !== state.user?.email || logout) {
        dispatch({ loggedIn: false, user: null });
        history.push("/");
        return;
      }

      setAlert("Account updated successfully.");
      dispatch({
        loggedIn: true,
        user: {
          id: user.data.id,
          email: user.data.email,
          name: user.data.name,
          providerId: user.data.providerId,
          createdAt: user.data.createdAt,
        },
      });
      reset();
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert("");
    }, 5000);

    return () => clearTimeout(timeout);
  }, [alert]);

  if (state.loggedIn === false || state.user === null) {
    return (
      <div className="container">You must be logged in to view this page.</div>
    );
  }

  return (
    <div className="container">
      <h1>Account</h1>
      <h3>👋 Welcome, {state.user.name}</h3>
      <nav className="mt-3">
        <div className="nav nav-pills" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-overview-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-overview"
            type="button"
            role="tab"
            aria-controls="nav-overview"
            aria-selected="true"
          >
            Overview
          </button>
          <button
            className="nav-link"
            id="nav-edit-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-edit"
            type="button"
            role="tab"
            aria-controls="nav-edit"
            aria-selected="false"
          >
            Edit Account
          </button>
        </div>
      </nav>
      <div className="tab-content mt-3" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-overview"
          role="tabpanel"
          aria-labelledby="nav-overview-tab"
        >
          <h4>Overview</h4>
          <div className="card">
            <div className="card-body">
              <strong>Name:</strong> {state.user.name}
              <br />
              <strong>Email:</strong> {state.user.email}
              <br />
              <strong>
                You joined{" "}
                {formatDistance(new Date(state.user.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </strong>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-edit"
          role="tabpanel"
          aria-labelledby="nav-edit-tab"
        >
          <h4>Edit Account</h4>
          <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <div className="card">
              <div className="card-body">
                <h5>Change Profile</h5>
                {alert && <div className="alert alert-success">{alert}</div>}
                <Input<User>
                  id="name"
                  label="Name"
                  register={register}
                  required={true}
                  defaultValue={state.user.name}
                  invalid={!!userErrors.name}
                />
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5>Change Credentials</h5>
                <p className="fw-bold">
                  Note: Changing your credentials will log you out.
                </p>
                {errors && errors.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    <ul className="mb-0">
                      {errors.map((error, i) => (
                        <li key={i}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}

                <Input<User>
                  id="email"
                  label="Email"
                  register={register}
                  required={true}
                  defaultValue={state.user.email}
                  invalid={!!userErrors.email}
                  helpText="This is your username"
                />

                <Input<User>
                  id="password"
                  label="Password"
                  register={register}
                  required={false}
                  type="password"
                />

                <Input<User>
                  id="passwordConfirmation"
                  label="Password Confirmation"
                  register={register}
                  required={false}
                  type="password"
                />
              </div>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto mt-4">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  reset();
                  setErrors([]);
                  setAlert("");
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!formState.isDirty}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Account;
