import {
  faEnvelope,
  faEnvelopeOpen,
  faSearchPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "bootstrap";
import { format, formatISO } from "date-fns";
import { useQuery } from "jsonapi-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { Message } from "./FormShow";

function Submissions() {
  const pageSize = 50;
  const { formId } = useParams<{ formId: string }>();
  const [page, setPage] = React.useState(1);
  const [selectedMessage, setSelectedMessage] = React.useState<Message | null>(
    null
  );
  const { data, isLoading, client, meta, refetch } = useQuery<Message[]>([
    "forms",
    formId,
    "messages",
    {
      page,
      size: pageSize,
    },
  ]);

  const maxPage = Math.ceil(meta?.total / pageSize) || 1;

  useEffect(() => {}, [page]);

  const messageSummary = (message: Message): string => {
    let values = Object.values(message.message);
    return values.join(", ").substring(0, 300);
  };

  const showSelectedMessage = async (message: Message) => {
    setSelectedMessage(message);

    await client?.mutate(["forms", formId, "messages", message.id], {
      isRead: true,
    });

    const modalElement = document.getElementById("messageModal");
    if (!modalElement) {
      return;
    }

    const modal = Modal.getOrCreateInstance(modalElement);
    if (modal) {
      modal.show();
    }
  };

  const markUnread = async (message: Message) => {
    await client?.mutate(["forms", formId, "messages", message.id], {
      isRead: false,
    });

    const modalElement = document.getElementById("messageModal");
    if (!modalElement) {
      return;
    }

    const modal = Modal.getInstance(modalElement);
    if (modal) {
      modal.hide();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div className="container">Could not find form</div>;
  }

  data.sort((a: Message, b: Message) => {
    if (!a || !b) {
      return 0;
    }
    return b.createdAt.getTime() - a.createdAt.getTime();
  });

  const groups = data.reduce((groups: any, message) => {
    const date = formatISO(message.createdAt).split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  return (
    <div className="container">
      <h1>Submissions</h1>
      <div className="accordion mb-3" id="submissions">
        {Object.keys(groups).map((date) => (
          <div className="accordion-item" key={date}>
            <h2 className="accordion-header" id={`heading${date}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${date}`}
                aria-expanded="false"
                aria-controls={`collapse${date}`}
              >
                <div className="d-flex w-100 justify-content-between align-items-center pe-2">
                  <span>{date}</span>
                  <span className="badge bg-primary">
                    {groups[date].length}
                  </span>
                </div>
              </button>
            </h2>
            <div
              id={`collapse${date}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${date}`}
              data-bs-parent="#submissions"
            >
              <div className="accordion-body">
                <ul className="list-group list-group-flush">
                  {groups[date].map((message: Message) => (
                    <li className="list-group-item" key={message.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {message.isRead ? (
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faEnvelopeOpen}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faEnvelope}
                            />
                          )}
                          <span className="date">
                            {format(message.createdAt, "p")}
                          </span>
                        </div>
                        <button
                          className="btn btn-link"
                          onClick={() => showSelectedMessage(message)}
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </button>
                      </div>
                      <small className="text-muted summary text-break">
                        {messageSummary(message)}
                      </small>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <button
          disabled={page <= 1}
          className="btn btn-secondary me-3"
          onClick={() => {
            setPage(page - 1);
            refetch && refetch();
          }}
        >
          &lt;&lt;
        </button>
        <div className="d-flex flex-column align-items-center">
          <div>
            Page {page} of {maxPage}
          </div>
          <small className="text-muted">({pageSize} per page)</small>
        </div>
        <button
          disabled={page === maxPage}
          className="btn btn-secondary ms-3"
          onClick={() => {
            setPage(page + 1);
            refetch && refetch();
          }}
        >
          &gt;&gt;
        </button>
      </div>
      <div
        className="modal fade"
        id="messageModal"
        tabIndex={-1}
        aria-labelledby="messageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary text-white">
              <h5 className="modal-title" id="messageModalLabel">
                {selectedMessage?.createdAt?.toLocaleString()}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                {Object.keys(selectedMessage?.message || {}).map((key) => (
                  <li className="list-group-item text-break" key={key}>
                    <strong>{key}:</strong> {selectedMessage?.message[key]}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => markUnread(selectedMessage!)}
              >
                Mark Unread
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Submissions;
