import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCloud } from "@fortawesome/pro-solid-svg-icons";
import phone from "../assets/contact-us-iphone.png";
import React from "react";

function Home() {
  return (
    <div className="home h-100 text-center">
      <div className="blurb d-inline-block">
        <h1>Handle any form with ease</h1>
        <div className="my-5">
          <h3>
            <FontAwesomeIcon className="text-info mx-2" icon={faCode} />
            <span>Your form code, our API.</span>
            <FontAwesomeIcon className="text-info mx-2" icon={faCloud} />
          </h3>
          <h5>
            With our API, data collection from your form is as easy as signing
            up!
          </h5>
        </div>
      </div>

      <div className="my-5">
        <h3 className="text-center mt-5 blurb d-inline">How it Works</h3>
        <div className="how-works">
          <h4 className="blurb">
            Take your standard "Contact Us" form on your website...
          </h4>
          <img src={phone} alt="Contact Us Form" />
          <h4 className="blurb">
            Simply point your form code's <code>action</code> to Post2API, and
            done!
            <br />
            <a href="/learn" className="btn btn-primary mt-3 btn-block">
              Learn More
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Home;
