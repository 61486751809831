import { useMutation } from "jsonapi-react";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  faCheckSquare,
  faLock,
  faSignature,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AuthContext } from "../components/AuthContext";
import { capitalize } from "../utils/helpers";

type ApiUser = {
  id: string;
  email: string;
  name: string;
  providerId: string;
  createdAt: Date;
};

function SignUp() {
  const [name, setName] = useState<string>();
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [addUser, { error }] = useMutation<ApiUser>("users");
  const [errors, setErrors] = useState<string[]>([]);

  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useContext(AuthContext);
  const validate = () => {
    if (name === undefined || name === "") {
      setErrors(["Name must exist"]);
      return false;
    }

    if (username === undefined || username === "") {
      setErrors(["Email must exist"]);
      return false;
    }

    if (password === undefined || password === "") {
      setErrors(["Password must exist"]);
      return false;
    }

    if (password.length < 6) {
      setErrors(["Password must be at least 6 characters"]);
      return false;
    }

    if (passwordConfirmation === undefined || passwordConfirmation === "") {
      setErrors(["Password Confirmation must exist"]);
      return false;
    }

    if (password !== passwordConfirmation) {
      setErrors(["Password doesn't match confirmation"]);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrors([]);
    const valid = validate();

    if (!valid) {
      return false;
    }

    try {
      const result = await addUser({
        email: username,
        password,
        provider: "local",
        name,
      });

      if (error) {
        setErrors(
          error.errors.map((e: { message: string }) => capitalize(e.message))
        );
        return false;
      }

      if (!result.data) {
        setErrors(["No Data in result"]);
        return false;
      }

      dispatch({
        loggedIn: true,
        user: {
          id: result.data?.id,
          email: result.data?.email,
          name: result.data?.name,
          providerId: result.data?.providerId,
          createdAt: result.data?.createdAt,
        },
      });

      history.push("/");
      return true;
    } catch (error: any) {
      setErrors(["Unknown Error"]);
      console.error(error);
      return false;
    }
  };

  return (
    <div className="container">
      <div className="utility-form">
        <div className="text-center mb-4">
          <h3>Create Account</h3>
          <p className="lead">Start doing things in an instant</p>
        </div>
        <form
          onSubmit={(e) => {
            return handleSubmit(e);
          }}
        >
          {errors && errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              <ul className="mb-0">
                {errors.map((e: string) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faSignature} />
            </span>
            <input
              className="form-control border-start-0 ps-1"
              id="name"
              placeholder="Display Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon fixedWidth={true} icon={faUser} />
              </span>
              <input
                type="email"
                className="form-control border-start-0 ps-1"
                placeholder="Email address"
                id="email"
                aria-describedby="emailHelp"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <small id="emailHelp" className="text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon fixedWidth={true} icon={faLock} />
              </span>
              <input
                type="password"
                className="form-control border-start-0 ps-1"
                placeholder="Password"
                id="password"
                aria-describedby="passwordHelp"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <small id="passwordHelp" className="text-muted">
              Your password should be at least 6 characters
            </small>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faCheckSquare} />
            </span>
            <input
              type="password"
              className="form-control border-start-0 ps-1"
              placeholder="Password Confirmation"
              id="password-confirmation"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <div className="d-grid col-6 mx-auto">
            <button type="submit" className="btn btn-primary">
              Create Account
            </button>
          </div>
        </form>
        <div className="text-center mt-2">
          <small className="text-muted">
            Already have an account? <Link to="/login">Login</Link>
          </small>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
