import React, { ReactNode, useEffect, useMemo, useReducer } from "react";

export type User = {
  id: string;
  password?: string;
  passwordConfirmation?: string;
  email: string;
  name: string;
  providerId: string;
  createdAt: Date;
};

export type State = {
  loggedIn: boolean;
  user: User | null;
};

const initialState: State = JSON.parse(
  (localStorage.getItem("auth") as string) || "{}"
);

const updater = (state: State, update: Partial<State>): State => {
  return { ...state, ...update };
};

type Props = {
  children: ReactNode[] | ReactNode;
};

type IAuthContext = [State, React.Dispatch<Partial<State>>];
const initialContext: IAuthContext = [{ ...initialState }, () => {}];

export const AuthContext = React.createContext<IAuthContext>(initialContext);

export function AuthController(props: Props) {
  const [authState, updateAuth] = useReducer(updater, initialState);
  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(authState));
  }, [authState]);

  const value = useMemo(() => [authState, updateAuth], [authState]) as [
    State,
    React.Dispatch<Partial<State>>
  ];
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
