import React, { useContext } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ApiClient, ApiProvider } from "jsonapi-react";

import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Home from "./routes/Home";
import Learn from "./routes/Learn";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import FormList from "./routes/FormList";
import FormShow from "./routes/FormShow";
import Submissions from "./routes/Submissions";
import Account from "./routes/Account";
import Help from "./routes/Help";
import schema from "./utils/schema";
import { AuthContext } from "./components/AuthContext";
import Page from "./components/Page";
import Submenu from "./components/Submenu";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, _] = useContext(AuthContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  const client = new ApiClient({
    url: apiUrl,
    schema,
    headers: {
      Authorization: `JWT ${state?.user?.providerId}`,
    },
  });

  return (
    <Router>
      <ScrollToTop />
      <ApiProvider client={client}>
        <Sidebar />
        <Submenu />
        <main className="flex-shrink-0">
          <Switch>
            <Page path="/login" component={Login} title="Login" />
            <Page path="/account" component={Account} title="Account" />
            <Page path="/signup" component={SignUp} title="Sign Up" />
            <Page path="/learn" component={Learn} title="Learn" />
            <Page path="/help" component={Help} title="Help" />
            <Page
              path="/forms/:formId/submissions"
              component={Submissions}
              title="Form Submissions"
            />
            <Page
              path="/forms/:formId"
              component={FormShow}
              title="Form Details"
            />
            <Page path="/forms" component={FormList} title="Forms" />
            <Page path="/" component={Home} title="Home" />
          </Switch>
        </main>
        <Footer />
      </ApiProvider>
    </Router>
  );
}

export default App;
