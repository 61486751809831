const schema = {
  messages: {
    type: "messages",
    fields: {
      createdAt: {
        type: "date",
      },
      updatedAt: {
        type: "date",
      },
    },
    relationships: {
      form: {
        type: "forms",
      },
    },
  },
  forms: {
    type: "forms",
    fields: {
      createdAt: {
        type: "date",
      },
      updatedAt: {
        type: "date",
      },
      messageCount: {
        readonly: true,
        resolve: (value: any, attrs: any, data: any) => {
          return data?.meta?.messageCount;
        },
      },
    },
    relationships: {
      user: {
        type: "users",
      },
      messages: {
        type: "messages",
      },
    },
  },
  users: {
    type: "users",
    fields: {
      createdAt: {
        type: "date",
      },
    },
    relationships: {
      forms: {
        type: "forms",
      },
    },
  },
};

export default schema;
