import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

function Submenu() {
  const history = useHistory();
  const { pathname } = useLocation();
  const [html, setHtml] = React.useState(<></>);

  useEffect(() => {
    if (
      pathname === "/account" ||
      pathname === "/learn" ||
      pathname === "/forms" ||
      pathname === "/help"
    ) {
      setHtml(
        <nav className="sub-menu" data-testid="sub-menu">
          <Link to="/">Home</Link>
        </nav>
      );
      return;
    }
    const submissionsRegex = /.*\/submissions$/i;
    if (pathname.match(submissionsRegex) !== null) {
      setHtml(
        <nav className="sub-menu" data-testid="sub-menu">
          <button
            className="btn btn-link"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Form Detail
          </button>
        </nav>
      );
      return;
    }
    const formsRegexp = /^\/forms\/.*/i;
    if (pathname.match(formsRegexp) !== null) {
      setHtml(
        <nav className="sub-menu" data-testid="sub-menu">
          <Link to="/forms">Forms</Link>
        </nav>
      );
      return;
    }

    setHtml(<></>);
  }, [pathname, history]);

  return html;
}

export default Submenu;
