function Footer() {
  return (
    <footer className="bg-dark py-3 px-4 d-flex w-100 justify-content-between mt-auto">
      <div className="text-info">{`v${process.env.REACT_APP_VERSION}`}</div>
      <div className="text-info">
        &copy; {new Date().getFullYear()} Post2API
      </div>
    </footer>
  );
}

export default Footer;
