import { Modal } from "bootstrap";
import { useMutation } from "jsonapi-react";
import { useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Form } from "../routes/FormShow";
import { AuthContext } from "./AuthContext";
import Input from "./Input";

type Props = {
  form: Form;
  id: string;
  title: string;
};

function FormModal({ form, id, title }: Props) {
  const {
    register,
    formState: { errors: formErrors },
    handleSubmit,
    reset,
  } = useForm<Form>();

  const [updateForm] = useMutation<Form>(["forms", form.id]);
  const [addForm] = useMutation<Form>("forms");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, _] = useContext(AuthContext);

  const onSubmit: SubmitHandler<Form> = async (results, e) => {
    try {
      if (form.id) {
        await updateForm({
          name: results.name,
          honeyPot: results.honeyPot,
          userId: state?.user?.id,
        });
      } else {
        await addForm({
          name: results.name,
          honeyPot: results.honeyPot,
          userId: state?.user?.id,
        });
      }

      const modalElement = document.getElementById(id);
      if (!modalElement) {
        return;
      }

      const modal = Modal.getInstance(modalElement);
      if (modal) {
        reset();

        modal.hide();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id={`${id}Label`}>
              {title}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Cancel"
              onClick={() => reset()}
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <div className="modal-body">
              <Input<Form>
                id="name"
                label="Form Name"
                register={register}
                required={true}
                defaultValue={form.name}
                invalid={!!formErrors.name}
                helpText="Be sure to give your form a meaningful name"
              />

              <Input<Form>
                id="honeyPot"
                label="Honey Pot"
                register={register}
                required={false}
                defaultValue={form.honeyPot}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => reset()}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FormModal;
