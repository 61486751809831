import { useContext } from "react";
import {
  faGraduationCap,
  faHome,
  faFileCode,
  faSignInAlt,
  faUserPlus,
  faSignOutAlt,
  faQuestionSquare,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, useHistory } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Gravatar from "react-gravatar";

import noUser from "../assets/no-user.png";

function Sidebar() {
  const [state, dispatch] = useContext(AuthContext);
  const history = useHistory();

  const logOut = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e?.currentTarget?.parentElement?.parentElement?.classList?.replace(
      "show",
      "hide"
    );

    dispatch({ loggedIn: false, user: null });
    history.push("/");
  };

  return (
    <nav
      className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top"
      data-testid="sidebar"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler me-3 border-0 p-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <NavLink className="navbar-brand me-auto" to="/">
          <img
            alt="Post2API"
            src="https://images.visoftinc.com/post2api/logo.png"
            width="125"
            height="50"
          />
        </NavLink>

        <div
          className="offcanvas offcanvas-start bg-dark"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5
              className="offcanvas-title text-white"
              id="offcanvasNavbarLabel"
            >
              Post2API
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasNavbar"
              >
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  aria-current="page"
                  to="/"
                  exact={true}
                >
                  <FontAwesomeIcon className="me-2" icon={faHome} />
                  <span>Home</span>
                </NavLink>
              </li>
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasNavbar"
              >
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  aria-current="page"
                  to="/learn"
                >
                  <FontAwesomeIcon className="me-2" icon={faGraduationCap} />
                  <span>Learn</span>
                </NavLink>
              </li>

              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasNavbar"
              >
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  aria-current="page"
                  to="/help"
                >
                  <FontAwesomeIcon className="me-2" icon={faQuestionSquare} />
                  <span>Help</span>
                </NavLink>
              </li>

              {state?.loggedIn && (
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                >
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    aria-current="page"
                    to="/forms"
                  >
                    <FontAwesomeIcon className="me-2" icon={faFileCode} />
                    <span>Forms</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="nav-item dropdown">
          {state?.user ? (
            <Gravatar
              className="dropdown-toggle gravatar-image rounded"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              email={state?.user?.email}
              size={40}
            />
          ) : (
            <div
              aria-expanded="false"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
            >
              <img
                alt="Not Logged In"
                className="dropdown-toggle gravatar-image rounded"
                src={noUser}
              />
            </div>
          )}

          {state?.loggedIn ? (
            <ul
              className="dropdown-menu dropdown-menu-light dropdown-menu-end"
              aria-labelledby="dropdownMenuButton2"
            >
              <li>
                <Link className="dropdown-item" to="/account">
                  <FontAwesomeIcon className="me-2" icon={faUser} />
                  Account
                </Link>
              </li>
              <li>
                <button className="dropdown-item btn btn-link" onClick={logOut}>
                  <FontAwesomeIcon className="me-2" icon={faSignOutAlt} />
                  Log out
                </button>
              </li>
            </ul>
          ) : (
            <ul
              className="dropdown-menu dropdown-menu-light dropdown-menu-end"
              aria-labelledby="dropdownMenuButton2"
            >
              <li>
                <Link className="dropdown-item" to="/login">
                  <FontAwesomeIcon className="me-2" icon={faSignInAlt} />
                  Log in
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/signup">
                  <FontAwesomeIcon className="me-2" icon={faUserPlus} />
                  Sign up
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
