import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistance } from "date-fns";
import { useQuery } from "jsonapi-react";
import { Link } from "react-router-dom";

import FormModal from "../components/FormModal";
import Loader from "../components/Loader";
import { Form } from "./FormShow";

function FormList() {
  const { data, isLoading } = useQuery("forms");

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div className="container">No forms found</div>;
  }

  data.sort((a: Form, b: Form) => {
    if (!a || !b || !a.createdAt || !b.createdAt) {
      return 0;
    }
    return b.createdAt.getTime() - a.createdAt.getTime();
  });

  return (
    <div className="container">
      <button
        id="add-form"
        className="btn btn-primary rounded-circle float-end"
        data-bs-toggle="modal"
        data-bs-target="#addFormModal"
        type="button"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <FormModal
        form={{ id: "", name: "" }}
        id="addFormModal"
        title="Add Form"
      />
      <h1>Forms</h1>
      <p className="text-muted">Forms for all your contact needs</p>
      <div className="list-group forms-list">
        {data.length === 0 ? (
          <p>There are no forms yet!</p>
        ) : (
          data.map((form: any) => {
            return (
              <Link
                to={`/forms/${form.id}`}
                key={form.id}
                className="list-group-item list-group-item-action flex-column align-items-start forms-item"
              >
                <div className="d-flex width-100 align-items-center">
                  <div className="d-flex w-100 flex-column">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">{form.name}</h5>
                      <small className="text-muted">
                        {formatDistance(
                          Date.parse(form.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </small>
                    </div>
                    <div className="d-flex w-100 justify-content-end">
                      <span className="badge rounded-pill bg-primary">
                        {form.messageCount || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
}

export default FormList;
