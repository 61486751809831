import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToTop } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import code from "../assets/code.png";
import contactForm from "../assets/contact-form.png";

function Learn() {
  return (
    <div className="container">
      <h1 id="top" className="anchor">
        Learn
      </h1>
      <p className="lead">Post2API is simple to learn</p>

      <h2 id="signup">
        <a className="anchor text-decoration-none" href="#signup">
          Signing Up
        </a>
      </h2>
      <p>
        First step in getting started is signing up for a new account. We simply
        need your name and email, along with a password. The email address that
        you enter will be your login name, as well as the mailbox where you will
        be receiving new form submissions.
      </p>
      <a className="text-decoration-none d-flex" href="#top">
        <FontAwesomeIcon className="ms-auto me-2" icon={faArrowToTop} />
      </a>

      <h2 id="forms" className="mt-4">
        <a className="anchor text-decoration-none" href="#forms">
          Forms Overview
        </a>
      </h2>
      <p>
        Forms on Post2API mirror web forms on your website. Be it a "Contact Us"
        form, or any other type of form, we will capture submissions and email
        you each time you receive a new one. Doing this, requires a simple
        change to your code. Say you have something like the following code:
      </p>
      <img alt="Sample Form Code" src={code} className="w-100" />
      <p className="mt-3">
        All you would need to do here, is replace the form <code>action</code>{" "}
        (the highlighted part of the example) with the URL from the Form Detail
        page. Redeploy to your website, and that's all!
      </p>
      <a className="text-decoration-none d-flex" href="#top">
        <FontAwesomeIcon className="ms-auto me-2" icon={faArrowToTop} />
      </a>

      <h2 id="form-honey-pot" className="mt-4">
        <a className="anchor text-decoration-none" href="#form-honey-pot">
          Forms: Honey Pot
        </a>
      </h2>
      <p>
        When you have a contact or other web form on your site, SPAM is a big
        problem typically. One easy technique for thwarting bots and unwanted
        messages is using a Honey Pot.
      </p>
      <p>
        A Honey Pot is a hidden field (but not an{" "}
        <code>&lt;input type="hidden" ...&gt;</code>) on a form. Take a look at
        the following form:
      </p>
      <img alt="Sample Contact Form" src={contactForm} className="w-100" />
      <p className="mt-3">
        This form looks pretty standard. If we look at the code. however, we
        would see there is an extra <code>&lt;input type="text" ...&gt;</code>{" "}
        element on the form. It is hidden through some special CSS class on the
        element, so it isn't visible to anyone except bots. A bot is a computer,
        and reads code to fill in forms.
      </p>
      <p>
        The idea is that it looks like a valid text field in code. We assign it
        a <code>name</code> that we check for when a form is submitted. If that
        field has a <code>value</code>, we don't log or send you the message.
        When you edit your form, or create a new one, you can change/add a Honey
        Pot.
      </p>
      <p>
        On the form details page, you'll see a simple example of a hidden text
        field, it will have the Honey Pot value that you entered so it's just
        copy and paste. It will look something like this:{" "}
        <code>
          &lt;input type="text" name="honey-pot" style="display: none"&gt;
        </code>
        <a className="text-decoration-none d-flex" href="#top">
          <FontAwesomeIcon className="ms-auto me-2" icon={faArrowToTop} />
        </a>
      </p>
    </div>
  );
}

export default Learn;
