import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "jsonapi-react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  faEdit,
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { Modal } from "bootstrap";
import { HashLink } from "react-router-hash-link";

import FormModal from "../components/FormModal";
import Loader from "../components/Loader";

export type Form = {
  id: string;
  name: string;
  honeyPot?: string;
  createdAt?: Date;
  updatedAt?: Date;
  messageCount?: number;
  messages?: Message[];
};

export type Message = {
  id: string;
  formId: string;
  message: any;
  isRead: boolean;
  isEmailed: boolean;
  createdAt: Date;
  updatedAt: Date;
};

function FormShow() {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();
  const { data, client, isLoading } = useQuery<Form>(["forms", formId]);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div className="container">Could not find form</div>;
  }

  const deleteForm = async () => {
    try {
      const { error } = await client.delete(["forms", formId]);
      if (error) {
        console.error(error);
        return;
      }

      const modalElement = document.getElementById("deleteFormModal");
      if (!modalElement) {
        return;
      }

      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }

      history.push("/forms");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>{data.name}</h1>
        <button
          className="btn btn-link"
          data-bs-toggle="modal"
          data-bs-target="#formModal"
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </div>
      <FormModal form={data} id="formModal" title="Edit Form" />
      <div className="d-flex justify-content-between align-items-center">
        <h4>
          Your Form's <code>action</code>
        </h4>
        <button
          className="btn btn-link"
          data-bs-toggle="collapse"
          data-bs-target="#formHelp"
          aria-expanded="false"
          aria-controls="formHelp"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </button>
      </div>
      <div className="mb-4 border border-primary p-3 rounded">
        <strong>https://api.post2api.com/f/{data.id}</strong>
        <div className="collapse mt-3" id="formHelp">
          <p>
            All you need to do is take the URL above and replace your web form's{" "}
            <code>action</code>.
          </p>
          <p>
            Be sure to also set your form's <code>method</code> to{" "}
            <code>"post"</code>
          </p>
          <p>Here is an example form with your form id embedded in it:</p>
          {/* prettier-ignore */}
          <pre>{`
  <form action="https://api.post2api.com/f/${data.id}" method="post">
    <input type="text" name="name" placeholder="Name">
    <input type="text" name="email" placeholder="Email Address">
    <textarea name="content" placeholder="Content"></textarea>
    <input type="submit" value="Submit">
  </form>`}
          </pre>
          <div className="d-grid col-lg-5 col-8 mt-2 mx-auto">
            <HashLink to="/learn#forms" className="btn btn-primary btn-block">
              Learn More
            </HashLink>
          </div>
        </div>
      </div>
      {data.honeyPot && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Honey Pot</h4>
            <button
              className="btn btn-link"
              data-bs-toggle="collapse"
              data-bs-target="#honeyPotHelp"
              aria-expanded="false"
              aria-controls="honeyPotHelp"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
          </div>
          <div className="mb-4 border border-primary p-3 rounded">
            <code>
              &lt;input type="text" name="{data.honeyPot}" style="display:
              none"&gt;
            </code>
            <div className="collapse mt-3" id="honeyPotHelp">
              <p>
                What is a HoneyPot? Basically a fake field on your form, that if
                it's filled in, we know it's SPAM
              </p>
              <p>
                You can just copy the example above and add it to your form.
              </p>
              <div className="d-grid col-lg-5 col-8 mt-2 mx-auto">
                <HashLink
                  to="/learn#form-honey-pot"
                  className="btn btn-primary btn-block"
                >
                  Learn More
                </HashLink>
              </div>
            </div>
          </div>
        </>
      )}

      <h4>Submissions</h4>
      <div className="mb-4 border border-primary p-3 rounded">
        You have <strong>{data.messageCount}</strong> submissions.
        <div className="d-grid col-lg-5 col-8 mt-2 mx-auto">
          <Link
            to={`/forms/${formId}/submissions`}
            className={`btn btn-primary btn-block ${
              data.messageCount === 0 ? "disabled" : ""
            }`}
            role="button"
            tabIndex={data.messageCount === 0 ? -1 : 0}
            aria-disabled={data.messageCount === 0}
          >
            View Submissions
          </Link>
        </div>
      </div>

      <h4 className="text-danger">Danger Zone</h4>
      <div className="border border-danger p-3 rounded">
        <div>
          <button
            className="btn btn-danger float-end ms-2"
            data-bs-toggle="modal"
            data-bs-target="#deleteFormModal"
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              inverse={true}
              className="me-2"
            />
            Delete Form
          </button>
          <p>
            <strong>Delete this form?</strong>
            <br />
            Once you delete a form, there is no going back.
          </p>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteFormModal"
        tabIndex={-1}
        aria-labelledby="deleteFormModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-danger text-white">
              <h5 className="modal-title" id="deleteFormModalLabel">
                Confirm Delete
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Cancel"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this form and all your{" "}
                {data.messageCount} messages for it?
              </p>
              <p>
                You will no longer be able to collect for submissions through
                your website.
              </p>
              <p>
                <strong>This cannot be undone.</strong>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteForm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormShow;
