import React from "react";

function Help() {
  return (
    <div className="container">
      <h1 className="h2">Help</h1>
      <p className="text-muted">Need help? You've come to the right place!</p>

      <h2>Adding a form</h2>
      <p>
        Adding a new form is simple. All you need to set up a new form is a form
        name. Once you are ready, simply click on the + symbol on the Forms
        screen. You will be presented with a window prompting you to enter the
        form name. Either hit enter or use the "Add Form" button to complete the
        task. It will appear in the list and from there you can start working
        with your form.
      </p>

      <h2>Using a form</h2>
      <p>
        Once you've created a new form, you can then start using it on your
        site. Simply take you form code and alter the submit action pointing it
        at the URL defined on the Form's Detail screen. There you will also find
        simple form code outlining exactly what your form action should look
        like. After you change the code on your website, you will be able to
        collect submissions through Post2API. You will receive and email each
        time your receive a new submission, and you'll be able to also view the
        submissions on the Post2API site.
      </p>
    </div>
  );
}

export default Help;
