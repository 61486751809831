import React from "react";
import { Path, UseFormRegister } from "react-hook-form";

type InputProps<T> = {
  id: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  required: boolean;
  defaultValue?: string;
  invalid?: boolean;
  helpText?: string;
  type?: string;
};

export default class Input<T> extends React.Component<InputProps<T>> {
  render() {
    return (
      <div className="mb-3">
        <div className="form-floating">
          <input
            type={this.props.type || "text"}
            className={`form-control ${this.props.invalid ? "is-invalid" : ""}`}
            id={this.props.id}
            {...this.props.register(this.props.id, {
              required: this.props.required,
            })}
            placeholder={this.props.label}
            defaultValue={this.props.defaultValue}
          />
          {this.props.invalid && (
            <div className="invalid-feedback">
              {this.props.label} is required
            </div>
          )}
          <label htmlFor={this.props.id}>{this.props.label}</label>
        </div>
        {this.props.helpText && (
          <small className="form-text text-muted">{this.props.helpText}</small>
        )}
      </div>
    );
  }
}
